<script>
import {UTooltip} from "uloc-vue"

export default {
  name: "ListBtn",
  components: {
    UTooltip
  },
  props: {
    label: null,
    icon: null
  }
}
</script>

<template>
  <a @click="$emit('click')" class="list-btn-item">
    <i :class="icon"></i>
    <u-tooltip :offset="[5,5]">
      {{label}}
    </u-tooltip>
  </a>
</template>
